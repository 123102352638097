
.main-nav {
  position: relative;
}

ul.navbar {


  li {
    display: inline-block;
    font-size: 14px;
    letter-spacing: -0.5px;
    margin: 0 30px;
    font-weight: 400;
    a{
      color: #fff;
      line-height: 36px;
      font-weight: 400;
    }

  }

  .icon-login {
    padding: 0px 30px;
    background: linear-gradient(270deg, #E24137 0%, #FE0171 98.48%);
    border-radius: 5px;
    height: 36px;
    line-height: 36px;

    a {
      color:#fff;
      font-weight:500;
      font-size: 14px;
      line-height: 36px;
      display: block;

      span{line-height: 36px;}

    }
  }

  .icon-logout {
    padding: 0px 30px;
    background: linear-gradient(270deg, #E24137 0%, #FE0171 98.48%);
    border-radius: 5px;
    height: 36px;
    line-height: 36px;

    a {
      color:#fff;
      font-weight:500;
      font-size: 14px;
      line-height: 36px;
      display: block;

      span{line-height: 36px;}

    }
  }

}
