
li {
  display: inline-block; 
  position: relative;
  margin: 0 25px; 

  div {
    display: inline-block;
  }

  span {
    font-size: 14px; 
    letter-spacing: -0.5px; 
    font-weight: 400;
    color: #fff;
  }

  .icon-wrapper {
    width: 25px;
    height: 25px;
    line-height: 25px;
    margin: auto;
    padding: 0;
    text-align: center;

    img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
    }
  }

}

